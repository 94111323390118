import Vue from 'vue';
import Vuetify from 'vuetify';
import fi from 'vuetify/lib/locale/fi';
import en from 'vuetify/lib/locale/en';
import 'vuetify/dist/vuetify.min.css';


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: {fi, en},
    current: 'fi',
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
      variations: true,
    },
    themes: {
      light: {
        primary: '#124a52',
        secondary: '#ff5805',
        accent: '#3cdbc0',
        "accent-2": '#f9b526',
        "accent-3": '#666680',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
      },
      dark: {
        primary: '#124a52',
        secondary: '#ff5805',
        accent: '#3cdbc0',
        "accent-2": '#f9b526',
        "accent-3": '#666680',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
      }
    }
  }
});
