<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark :color="options.color">
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-5" v-show="!!message">{{ message }}</v-card-text>
      <v-card-text v-if="options.comment">
        <v-form ref="form">
          <v-textarea :rules="[$rules.required]" v-model="comment" rows="3" :label="$t('comment')" />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="cancel()">{{options.cancel}}</v-btn>
        <v-btn text :color="options.color" v-if="options.agree" @click.native="agree()">{{options.agree}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {});
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open;
 * }
 */
export default {
  name: 'ConfirmDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      comment: '',
      options: {
        color: 'primary',
        agree: this.$t('yes'),
        cancel: this.$t('no'),
        width: 390,
        comment: false,
      }
    };
  },
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (this.options.comment) {
        if (!this.$refs.form.validate()) return;
        this.resolve(this.comment);
      } else {
        this.resolve(true);
      }
      this.$refs.form.resetValidation();
      this.comment = '';
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.comment = '';
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
  },
}
</script>
