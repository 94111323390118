<template>
  <hb-basic-page :title="job.name"
                 :loading="loading"
                 :fill-height="loading"
                 :back-button="{show: true, fallback: 'job_ads'}"
                 :subtle-loading="subtleLoading"
                 :context-menu="contextMenuItems"
                 name="single-job-ad-view">

    <template v-slot:app-bar-extension>
      <v-tabs v-model="tab" background-color="transparent" color="primary">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab :key="0" ripple>
          {{ $t('job_ads.basic_info') }}
        </v-tab>
        <v-tab :key="1" ripple>
          {{ $t('job_ad.applications') }}
        </v-tab>
        <v-tab :key="2" ripple @change="reloadSuggestions">
          {{ $t('job_ad.suggestions') }}
        </v-tab>
      </v-tabs>
    </template>


    <v-tabs-items v-model="tab" class="transparent">
      <v-tab-item>
        <v-col cols="12" class="pt-0" style="position: relative;">
          <v-row style="position: relative;">
            <v-col cols="12" md="5" xl="4">

              <v-card class="mb-6" v-if="!$likeitEnabled">
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.state') }}
                </v-card-title>
                <v-card-text>
                  <v-btn-toggle rounded color="secondary" mandatory v-model="state" @change="updateJobState">
                    <template v-for="state in allStates">
                      <v-btn :key="state.value" :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xsOnly" :value="state.value">{{ state.text }}</v-btn>
                    </template>
                  </v-btn-toggle>
                </v-card-text>
              </v-card>

              <v-card class="mb-6">
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.tracking') }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6">
                      <span class="secondary--text">{{ $t('job_ads.opened') }}:</span>
                      <br />
                      <span class="text-h4 text-sm-h3 font-weight-bold">{{ job.openCount }}</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="secondary--text">{{ $t('job_ads.sent') }}:</span>
                      <br />
                      <span class="text-h4 text-sm-h3 font-weight-bold">{{ job.submitCount }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.job_details') }}
                </v-card-title>
                <v-card-text>

                  <div v-html="job.description" style="white-space: pre-line;"></div>

                  <v-list dense class="px-0">
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.client')" :value="jobAdCustomer(job)" />
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.search_time')" :value="`${$formatDateNoTime(job.searchStartDate)} - ${$formatDateNoTime(job.searchEndDate)}`" />
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.number_of_positions')" :value="job.positions" />
                    <v-divider></v-divider>
                  </v-list>

                </v-card-text>
                <v-card-actions>
                  <v-btn :href="likeitAdvertUrl" color="secondary" text v-if="$likeitEnabled">{{ $t('job_ads.edit_in_likeit') }}</v-btn>
                  <v-btn @click="handleEditBasicInfo" color="secondary" text v-else>{{ $t('edit') }}</v-btn>
                </v-card-actions>
              </v-card>

              <v-card class="mt-6">
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.additional_info') }}
                </v-card-title>
                <v-card-text>
                  <v-list dense class="px-0">
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.detail.industry')" :value="industryName" />
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.detail.alternative_industries')" :value="alternativeIndustryNames" />
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.detail.type')" :value="job.type" />
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.detail.length')" :value="job.length" />
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.detail.location')" :value="job.location" />
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.detail.starts')" :value="job.starts" />
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.detail.salary')" :value="job.salary" />
                    <v-divider></v-divider>
                    <JobDetailItem :label="$t('job_ads.detail.contact_name')" :value="job.contactEmail" />
                    <v-divider></v-divider>
                    <JobDetailLink :label="$t('job_ads.detail.link')" :value="job.link" />
                    <v-divider></v-divider>
                    <JobDetailLink :label="$t('job_ads.detail.youtube_link')" :value="job.youtubeLink" />
                    <v-divider></v-divider>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="secondary" text @click="handleEditDetails">{{ $t('edit') }}</v-btn>
                </v-card-actions>
              </v-card>

              <v-card class="mt-6" :loading="savingImage">
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.hero_image') }}
                </v-card-title>
                <v-card-text>
                  <template v-if="job.imageKey">
                    <v-img :src="$getImageUrl(job.imageKey)" contain></v-img>
                  </template>
                  <template v-else>
                    <v-alert type="info" :value="true" text dense>
                      {{ $t('job_ads.hero_image_help') }}
                    </v-alert>
                  </template>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="secondary" text @click="changeImage">{{ job.imageKey ? $t('change_image') : $t('add_image') }}</v-btn>
                  <v-btn color="secondary" v-if="job.imageKey" text @click="deleteImage">{{ $t('delete_image') }}</v-btn>
                </v-card-actions>
              </v-card>

              <v-card class="mt-6" :loading="savingCustomerImage">
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.customer_image') }}
                </v-card-title>
                <v-card-text>
                  <template v-if="job.customerImageKey">
                    <v-img :src="$getImageUrl(job.customerImageKey)" contain></v-img>
                  </template>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="secondary" text @click="changeCustomerImage">{{ job.customerImageKey ? $t('change_image') : $t('add_image') }}</v-btn>
                  <v-btn color="secondary" v-if="job.customerImageKey" text @click="deleteCustomerImage">{{ $t('delete_image') }}</v-btn>
                </v-card-actions>
              </v-card>

              <v-card class="mt-6">
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.open_application') }}
                </v-card-title>
                <v-card-text>
                  {{ $t('job_ads.open_application_explained') }}
                </v-card-text>
                <v-card-actions>
                  <v-switch :loading="updatingOpenApplicationInfo" v-model="openApplication" @change="updateOpenApplication" :label="$t('job_ads.job_is_open_application')" />
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12" md="7" xl="8">

              <v-card>
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.tags') }}
                </v-card-title>
                <v-card-text>
                  <v-alert type="info" :value="true" text dense>
                    {{ $t('job_ads.tags_help') }}
                  </v-alert>
                  <v-divider></v-divider>
                  <v-col cols="12">
                    <v-combobox outlined
                                :label="$t('job_ads.required_tags')"
                                :items="allTags"
                                v-model="job.requiredTags"
                                item-value="id"
                                item-text="description"
                                @input="requiredTagsOnInput"
                                multiple
                                deletable-chips
                                chips
                                small-chips
                                hide-details></v-combobox>
                  </v-col>
                  <v-divider></v-divider>
                  <v-col cols="12">
                    <v-combobox outlined
                                :label="$t('job_ads.exclusive_tags')"
                                :items="allTags"
                                v-model="job.exclusiveTags"
                                item-value="id"
                                item-text="description"
                                @input="exclusiveTagsOnInput"
                                multiple
                                deletable-chips
                                chips
                                small-chips
                                hide-details></v-combobox>
                  </v-col>
                  <v-divider></v-divider>
                </v-card-text>
              </v-card>

              <v-card class="mt-6">
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.cards_and_permits') }}
                </v-card-title>
                <v-card-text>
                  <v-switch :disabled="subtleLoading" v-model="job.askCardsAndPermits" @change="handleSaveJobAd" :label="$t('job_ads.ask_cards_and_permits')" hide-details />
                </v-card-text>
                <v-expand-transition>
                  <v-card-text v-if="job.askCardsAndPermits">
                    <hb-licence-selector :selected-items="requiredLicences" @change="requiredLicencesChanged"></hb-licence-selector>
                  </v-card-text>
                </v-expand-transition>
              </v-card>

              <v-card class="mt-6">
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.experience_and_education') }}
                </v-card-title>
                <v-card-text>
                  <v-switch :disabled="subtleLoading" v-model="job.askExperience" @change="handleSaveJobAd" :label="$t('job_ads.ask_experience')" hide-details />
                  <v-switch :disabled="subtleLoading" v-model="job.askEducation" @change="handleSaveJobAd" :label="$t('job_ads.ask_education')" hide-details />
                </v-card-text>
              </v-card>

              <v-card class="mt-6">
                <v-card-title class="headline text-break">
                  {{ $t('job_ads.questions') }}
                </v-card-title>
                <v-card-text>
                  <v-alert type="info" :value="similarJobAds.length > 0 && job.missingQuestions" text dense>
                    {{ $t('job_ads.similar_jobs_help') }}
                    <div class="mt-3">
                      <v-btn @click="openSimilarJobs" small color="info" outlined>{{ $t('job_ads.check_job_ads') }}</v-btn>
                    </div>
                  </v-alert>
                </v-card-text>
                <v-card-actions class="py-0">
                  <v-btn text color="secondary" @click="hideQuestionDetails = !hideQuestionDetails">{{ hideQuestionDetails ? $t('show_details') : $t('hide_details') }}</v-btn>
                </v-card-actions>
                <v-card-text>
                  <v-text-field v-model="questionSearchPhrase"
                                :label="`${$t('questions_management.search')}...`"
                                dense
                                hide-details
                                outlined>
                    <v-icon style="cursor: pointer;" slot="append">search</v-icon>
                  </v-text-field>
                </v-card-text>
                <v-card-text class="body-1">
                  <v-btn block text outlined color="primary" class="mb-3" v-if="$vuetify.breakpoint.mdAndUp" @click="addNewQuestion">
                    <v-icon left>add</v-icon>
                    {{ $t('questions_management.add_new_question') }}
                  </v-btn>
                  <draggable v-model="job.questions" @end="questionDragEnd" :move="questionDragMove" handle=".handle">
                    <template v-for="question in filteredQuestions">
                      <QuestionCard :class="questionSearchPhrase ? '' : 'handle'"
                                    :question="question"
                                    :key="question.id"
                                    :show-answer-count="true"
                                    :hide-details="hideQuestionDetails"
                                    :selected-answers="selectedAnswers"
                                    @handle-answer-select="handleAnswerSelect"
                                    @handle-edit="handleQuestionEdit"
                                    @handle-delete="handleQuestionDelete" :delete-text="$t('job_ads.remove_question')"/>
                    </template>
                  </draggable>
                  <v-btn block text outlined color="primary" v-if="$vuetify.breakpoint.mdAndUp && filteredQuestions.length > 5" @click="addNewQuestion">
                    <v-icon left>add</v-icon>
                    {{ $t('questions_management.add_new_question') }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>

          </v-row>

        </v-col>

        <v-btn v-if="$vuetify.breakpoint.smAndDown" bottom fixed elevation="5" rounded right color="primary" @click="addNewQuestion">
          <v-icon left>add</v-icon>
          {{ $t('questions_management.add_new_question') }}
        </v-btn>
      </v-tab-item>
      <v-tab-item>

        <single-job-ad-applications
          :job="job"
          :all-statuses="allStatuses"
          :all-results="allResults"
        />

      </v-tab-item>
      <v-tab-item>

        <single-job-ad-suggestions
          :job="job"
          ref="suggestionsTab"
        />

      </v-tab-item>
    </v-tabs-items>

    <EditJobDetailsDialog ref="editJobDetailsDialog" :job="job" @updated="handleUpdateJobAd"/>

    <EditJobBasicInfoDialog ref="editJobBasicInfoDialog" :job="job" :customers="customers" @updated="handleUpdateJobAd" />

    <AddQuestionsDialog ref="addQuestionsDialog" :job="job" @questions-changed="reloadData" />

    <EditQuestionDialog ref="editQuestionDialog" :job="job" @question-created="reloadData" @question-updated="reloadData" @question-copy-created="handleQuestionCopyCreate" />

    <AnswerTestSnackbar :selected-answers="selectedAnswers" :questions="job.questions" @handle-reset="clearSelectedAnswers" />

    <SimilarJobAdsQuestionSelectDialog ref="similarJobAdsQuestionSelectDialog" :job="job" :similar-job-ads="similarJobAds" @questions-changed="reloadData" />

    <input type="file" style="display:none" accept="image/*" :multiple="false" ref="imageFileInput" @change="onImageFileChanged">
    <input type="file" style="display:none" accept="image/*" :multiple="false" ref="customerImageFileInput" @change="onCustomerImageFileChanged">
  </hb-basic-page>
</template>

<script>
  import tenantApi from '@/api/tenant';
  import JobDetailItem from "@/components/Tenant/SingleJobAd/JobDetailItem";
  import QuestionCard from "@/components/Tenant/QuestionCard";
  import AddQuestionsDialog from "@/components/Tenant/SingleJobAd/AddQuestionsDialog";
  import EditQuestionDialog from "@/components/Tenant/EditQuestionDialog";
  import EditJobDetailsDialog from '@/components/Tenant/SingleJobAd/EditJobDetailsDialog';
  import draggable from 'vuedraggable';
  import AnswerTestSnackbar from "../../components/Tenant/AnswerTestSnackbar";
  import SimilarJobAdsQuestionSelectDialog from "../../components/Tenant/SingleJobAd/SimilarJobAdsQuestionSelectDialog";
  import JobDetailLink from '@/components/Tenant/SingleJobAd/JobDetailLink';
  import EditJobBasicInfoDialog from "@/components/Tenant/SingleJobAd/EditJobBasicInfoDialog";
  import systemApi from "@/api/system";
  import SingleJobAdApplications from '@/components/Tenant/SingleJobAd/SingleJobAdApplications';
  import HbLicenceSelector from "@/components/Tenant/HbLicenceSelector.vue";
  import SingleJobAdSuggestions from '@/components/Tenant/SingleJobAd/SingleJobAdSuggestions';

  export default {
    name: 'SingleJobAdView',
    props: ['id'],
    components: {
      SingleJobAdSuggestions,
      HbLicenceSelector,
      SingleJobAdApplications,
      EditJobBasicInfoDialog,
      SimilarJobAdsQuestionSelectDialog,
      AnswerTestSnackbar,
      EditQuestionDialog, AddQuestionsDialog, QuestionCard, JobDetailItem, JobDetailLink, EditJobDetailsDialog, draggable
    },
    data() {
      return {
        ready: this.$vuetify.breakpoint.smAndUp,
        loading: true,
        subtleLoading: false,
        questionSearchPhrase: '',
        tab: 0,
        hideQuestionDetails: false,
        job: {},
        allTags: [],
        savingImage: false,
        savingCustomerImage: false,
        updatingOpenApplicationInfo: false,
        openApplication: false,
        requiredLicences: [],
        selectedAnswers: [],
        allStates: [0, 1, 3, 2, 4].map(s => ({ value: s, text: this.$t('job_ads.states')[s]})),
        allStatuses: [],
        allResults: [],
        state: null,
        customers: [],
      }
    },
    methods: {
      async reloadSuggestions() {
        if (this.$refs.suggestionsTab) {
          await this.$refs.suggestionsTab.reloadApplicants();
        }
      },
      jobAdCustomer(job) {
        return job.customers && job.customers.length > 0 ? job.customers.map(c => c.name).join(', ') : '-';
      },
      async requiredLicencesChanged(licenses) {
        licenses = [...new Set(licenses)];
        try {
          await this.$tenantApi.updateJobAdLicenses(this.job.id, licenses);
          this.job = await this.$tenantApi.getJobAd(this.job.id);
        } catch (e) {
          this.$handleApiError(e);
        }
        this.requiredLicences = this.job.licenses?.map(l => l.code);
      },
      openSimilarJobs() {
        this.$refs.similarJobAdsQuestionSelectDialog.open();
      },
      handleAnswerSelect(answer) {
        const answerIndex = this.selectedAnswers.findIndex(a => a.id === answer.id);
        if (answerIndex > -1) {
          this.selectedAnswers.splice(answerIndex, 1);
        } else {
          this.selectedAnswers.push(answer);
        }
      },
      clearSelectedAnswers() {
        this.selectedAnswers = [];
      },
      clearLocalStorage() {
        localStorage.removeItem(`online_test_${this.$tenant}`)
      },
      async updateOpenApplication() {
        this.updatingOpenApplicationInfo = true;
        try {
          await tenantApi.setOpenApplication(this.job.id, this.openApplication);
        } catch (error) {
          this.$nextTick(() => {
            this.openApplication = !this.openApplication;
          });
          this.$handleApiError(error);
        }
        this.updatingOpenApplicationInfo = false;
      },
      changeImage() {
        this.$refs.imageFileInput.click();
      },
      changeCustomerImage() {
        this.$refs.customerImageFileInput.click();
      },
      async onImageFileChanged(fileAddedEvent) {
        const image = {
          file: fileAddedEvent.target.files[0],
          filename: fileAddedEvent.target.value.split('\\').pop()
        };

        this.savingImage = true;
        try {
          this.job = await tenantApi.updateJobAdImage(this.job.id, image.file, image.filename);
        } catch (error) {
          this.$handleApiError(error);
        }
        this.savingImage = false;
      },
      async onCustomerImageFileChanged(fileAddedEvent) {
        const image = {
          file: fileAddedEvent.target.files[0],
          filename: fileAddedEvent.target.value.split('\\').pop()
        };

        this.savingCustomerImage = true;
        try {
          this.job = await tenantApi.updateJobAdCustomerImage(this.job.id, image.file, image.filename);
        } catch (error) {
          this.$handleApiError(error);
        }
        this.savingCustomerImage = false;
      },
      async deleteImage() {
        try {
          await tenantApi.deleteJobAdImage(this.job.id);
          this.job.imageKey = null;
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async deleteCustomerImage() {
        try {
          await tenantApi.deleteJobAdCustomerImage(this.job.id);
          this.job.customerImageKey = null;
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async requiredTagsOnInput(linkedTags) {
        try {
          await tenantApi.setJobAdRequiredTags(this.job.id, linkedTags);
          this.tagsOnInput(linkedTags);
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      async exclusiveTagsOnInput(linkedTags) {
        try {
          await tenantApi.setJobAdExclusiveTags(this.job.id, linkedTags);
          this.tagsOnInput(linkedTags);
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      tagsOnInput(tags) {
        for (let i = 0; i < tags.length; i++) {
          const t = tags[i];
          if (!this.allTags.find(et => et.toLowerCase() === t.toLowerCase())) {
            this.allTags.push(t);
          }
        }
      },
      async handleQuestionCopyCreate(questionCopy, originalQuestion) {
        const originalQuestionIndex = this.job.questions.findIndex(q => q.id === originalQuestion.id);
        await this.handleQuestionDelete(originalQuestion, false);
        this.subtleLoading = true;
        if (originalQuestionIndex > -1) {
          const copyIndex = this.job.questions.findIndex(q => q.id === questionCopy.id);
          if (copyIndex > -1) {
            this.job.questions.splice(copyIndex, 1);
            this.job.questions.splice(originalQuestionIndex, 0, questionCopy);
          }
          try {
            await tenantApi.reorderJobAdQuestions(this.job.id, this.job.questions.map(q => q.id));
            await this.reloadData();
          } catch (error) {
            this.$handleApiError(error);
          }
        }
        this.subtleLoading = false;
      },
      async reloadData() {
        if (!this.loading) {
          this.subtleLoading = true;
        }
        try {
          this.customers = await tenantApi.getCustomers();
          this.job = await tenantApi.getJobAd(this.$route.params.id);
          this.requiredLicences = this.job.licenses?.map(l => l.code);
          this.state = this.job.state;
          this.allTags = await tenantApi.getTags();
          if (this.jobAds.length === 0) await this.$store.dispatch('reloadJobAds', {updateFromLikeit: false, options: { page: 0, itemsPerPage: 25 }});
          this.openApplication = this.job.openApplication;
        } catch (error) {
          this.$handleApiError(error);
        }
        this.subtleLoading = false;
      },
      handleUpdateJobAd(jobAd) {
        this.job = jobAd;
      },
      async handleSaveJobAd() {
        if (!this.job.id) return;
        this.subtleLoading = true;
        try {
          const jobAd = await tenantApi.updateJobAd(this.job.id, this.job);
          this.job = jobAd;
        } catch (err) {
          this.$handleApiError(err);
        }
        this.subtleLoading = false;
      },
      addNewQuestion() {
        this.$refs.addQuestionsDialog.open();
      },
      handleQuestionEdit(question) {
        this.$refs.editQuestionDialog.open(question);
      },
      async handleQuestionDelete(question) {
        this.subtleLoading = true;
        try {
          await tenantApi.removeQuestionFromJob(question.id, this.job.id);
          await this.reloadData();
        } catch (error) {
          this.$handleApiError(error);
        }
        this.subtleLoading = false;
      },
      handleEditDetails() {
        this.$refs.editJobDetailsDialog.open();
      },
      handleEditBasicInfo() {
        this.$refs.editJobBasicInfoDialog.open();
      },
      async questionDragEnd() {
        this.subtleLoading = true;
        try {
          await tenantApi.reorderJobAdQuestions(this.job.id, this.job.questions.map(q => q.id));
        } catch (error) {
          this.$handleApiError(error);
        }
        this.subtleLoading = false;
      },
      questionDragMove() {
        // disable reordering when list is filtered
        return !this.questionSearchPhrase;
      },
      async updateJobState() {
        this.subtleLoading = true;
        const job = {...this.job};
        job.state = this.state;
        try {
          const jobAd = await tenantApi.updateJobAd(job.id, job);
          this.job = jobAd;
        } catch (err) {
          this.state = this.job.state;
          this.$handleApiError(err);
        }
        this.subtleLoading = false;
      },
      async getApplicationStatuses() {
        try {
          const jobStatuses = await systemApi.getApplicationStatuses()
          const allStatuses = jobStatuses.filter(s => s !== 'NONE').map(s => ({ value: s, text: this.$t(`application.status.${s}`)}));
          allStatuses.unshift({ value: 'ALL', text: this.$t('application.status.ALL')});
          this.allStatuses = allStatuses;
        } catch (err) {
          this.$handleApiError(err);
        }
      },
      async getApplicationResults() {
        try {
          const jobResults = await systemApi.getApplicationResults()
          this.allResults = jobResults.map(r => ({ value: r, text: this.$t(`application.result.${r}`)}));
        } catch (err) {
          this.$handleApiError(err);
        }
      }
    },
    computed: {
      industryName() {
        return this.job.industry ? this.job.industry.name : '';
      },
      alternativeIndustryNames() {
        return this.job.alternativeIndustries ? this.job.alternativeIndustries.map(i => i.name).join(', ') : '';
      },
      jobAds() {
        return this.$store.state.jobAds;
      },
      similarJobAds() {
        const jobAds = this.$store.state.jobAds;
        if (Object.keys(this.job).length === 0) return [];
        const currentJob = this.job;
        const currentJobCustomerIds = currentJob.customers?.length > 0 ? currentJob.customers.map(c => c.id) : [];

        return jobAds.filter(jobAd =>
          jobAd.id !== currentJob.id &&
          !jobAd.missingQuestions &&
          (
            jobAd.name.toLowerCase().includes(currentJob.name.toLowerCase()) ||
            jobAd.customers.findIndex(jobAdCustomer => currentJobCustomerIds.includes(jobAdCustomer.id)) > -1)
          );
      },
      filteredQuestions() {
        const qs = this.job.questions || [];
        const searchPhrase = this.questionSearchPhrase.toLowerCase();
        return qs.filter(q =>
          q.questionText.toLowerCase().includes(searchPhrase) ||
          q.answers.flatMap(a => a.tags).some(t => t.toLowerCase().startsWith(searchPhrase)) ||
          q.requiredTags.some(t => t.description.toLowerCase().startsWith(searchPhrase)) ||
          q.classificationTags.some(t => t.description.toLowerCase().startsWith(searchPhrase))
        );
      },
      likeitAdvertUrl() {
        return `${process.env.VUE_APP_LIKEIT_ADVERT_PAGE}?id=${this.job.likeitId}`;
      },
      contextMenuItems() {
        if (this.job) {
          return [
            {
              callback: null,
              url: `./jobs#/tyopaikka/${this.job.id}?preview=${this.job.id}`,
              target: '_blank',
              text: this.$t('job_ads.show_preview'),
              icon: 'mdi mdi-eye'
            },
            {
              callback: this.clearLocalStorage,
              url: null,
              target: null,
              text: this.$t('online_test_management.clearLocalStorage'),
              icon: 'mdi mdi-trash-can-outline'
            }
          ]
        }
        return [];
      },
    },
    async mounted() {
      this.loading = true;
      await this.reloadData();
      await this.getApplicationStatuses();
      await this.getApplicationResults();
      if (this.$route.query.tab) {
        this.tab = parseInt(this.$route.query.tab);
      }
      this.loading = false;
      if (this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          this.ready = true
        }, 300);
      } else {
        this.ready = true;
      }
    },
    beforeRouteEnter(to, from, next) {
      if (from && from.name === 'single_application') {
        to.query.tab = 1;
      }
      next();
    },
  }
</script>
