<template>
  <v-col cols="12" class="applicants pt-0">
    <v-card>
      <v-list-item v-if="job.industry">
        <v-list-item-subtitle class="subtitle">{{ $t('job_ads.detail.industry') }}</v-list-item-subtitle>
        <v-list-item-title>{{ job.industry.name }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="alternativeIndustryNames">
        <v-list-item-subtitle class="subtitle">{{ $t('job_ads.detail.alternative_industries') }}</v-list-item-subtitle>
        <v-list-item-title>{{ alternativeIndustryNames }}</v-list-item-title>
      </v-list-item>
    </v-card>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="headline">
            {{ $t('job_ad.suggestions') }}
          </v-card-title>
          <v-card-text>
            <v-btn
              @click="sendEmailToSelected"
              :loading="sendingEmail"
              small
              class="mb-3"
              :disabled="selectedCount === 0">
              {{ sendEmailToSelectedText }}
            </v-btn>
            <v-btn
              @click="selectAll"
              :loading="sendingEmail"
              small
              class="mb-3 ml-3">
              {{ $t('applicants.select_all') }}
            </v-btn>
            <v-btn
              @click="selected={}"
              :loading="sendingEmail"
              small
              class="mb-3 ml-3"
              :disabled="selectedCount === 0">
              {{ $t('applicants.clear_selected') }}
            </v-btn>
            <v-data-table
              :headers="headers"
              :items="applicants"
              :loading="applicantsLoading"
              :server-items-length="totalApplicants"
              :page="pageNo"
              :items-per-page="pageSize"
              :footer-props="{ 'items-per-page-options': [10, 25, 50, -1]}"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :must-sort="true"
              @update:sort-by="debouncedReloadApplicants"
              @update:sort-desc="debouncedReloadApplicants"
              @pagination="paginate"
              class="elevation-1">
              <template v-slot:item.check="{ item }">
                <v-checkbox v-model="selected[item.email]"/>
              </template>
              <template v-slot:item.applicant="{ item }">
                <strong>{{ item.firstName }} {{ item.lastName }}</strong><br>
                {{ item.phone }}<br>
                {{ item.email }}
              </template>
              <template v-slot:item.background="{ item }">
                <div>
                  <div v-if="item.experience && item.experience.length > 0" class="my-2">
                    <div class="font-weight-bold">{{ $t('experience.title') }}:</div>
                    <div class="caption" style="line-height: 14px;">
                      <template v-for="experience in item.experience">
                        <div :key="experience.id">
                          {{ experience.industry ? experience.industry.name : '' }}: {{ experience.years === 0 ? $t(`experience.none`) : experience.years > 5 ? $t(`experience.over_x_years`, {x: 5}) : `${ experience.years } ${$tc('experience.year', experience.years)}` }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div v-if="item.education && item.education.length > 0" class="my-2">
                    <div class="font-weight-bold">{{ $t('education.title') }}:</div>
                    <div class="caption" style="line-height: 14px;">
                      <template v-for="education in item.education">
                        <div :key="education.id">
                          {{ education.industry ? education.industry.name + ': ' : '' }} {{ education.educationLevel ? $t('education.education_levels.' + education.educationLevel) : '' }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:item.link="{ item }">
                <v-btn color="primary" small outlined text :to="{name: 'single_applicant', params: { id: item.id } }">
                  <v-icon left>mdi-account-circle</v-icon>
                  {{$t('applicants.goto_profile')}}
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <send-email-dialog ref="sendEmailDialog" />
    <failed-email-dialog ref="failedEmailDialog" />

  </v-col>
</template>

<script>
import tenantApi from '@/api/tenant';
import SendEmailDialog from '@/components/SendEmailDialog';
import FailedEmailDialog from "@/components/Tenant/FailedEmailDialog.vue";
import {debounce} from "lodash";

export default {
  name: 'SingleJobAdSuggestions',
  components: {FailedEmailDialog, SendEmailDialog},
  props: {
    job: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
      applicantsLoading: false,
      allIndustires: [], // TODO: ehkä pitäisi siirtää props, muilla välilehdillä tarvitaan myös
      applicants: [],
      totalApplicants: 0,
      pageSize: 50,
      pageNo: 1,
      sortBy: 'updated',
      sortDesc: true,
      headers: [
        { text: '', value: 'check', sortable: false },
        { text: this.$t('job_ad.name_and_contact'), value: 'applicant', cols: '2' },
        { text: this.$t('applicants.industries'), value: 'background', sortable: false },
        { text: '', value: 'link', cols: '1' },
      ],
      sendingEmail: false,
      selected: {},
      failedCommunications: [],
    };
  },
  computed: {
    sendEmailToSelectedText() {
      return `${this.$t('applicants.send_email_to_selected')} (${this.selectedCount} ${this.$t('applicants.selected')})`;
    },
    selectedCount() {
      return Object.values(this.selected).filter(v => v).length;
    },
    alternativeIndustryNames() {
      return this.job.alternativeIndustries.map(i => i.name).join(', ');
    }
  },
  methods: {
    selectAll() {
      this.applicants.forEach(a => {
        this.$set(this.selected, a.email, true);
      });
    },
    async sendEmailToSelected() {
      this.sendingEmail = true;
      try {
        const emails = Object.entries(this.selected).filter(e => e[1]).map(e => e[0]);
        const [confirm, data] = await this.$refs.sendEmailDialog.open({}, emails);
        if (confirm) {
          const response = await tenantApi.sendEmail(data);

          if (response && response.errorCount > 0) {
            if (response.successfulCommunications && response.successfulCommunications.length > 0) {
              this.$showWarningNotification(this.$t('email.send_failed_partial'));
            } else {
              this.$showErrorNotification(this.$t('email.send_failed'));
            }
            this.showEmailFailedDialog(response);
          } else {
            this.$showSuccessNotification(this.$t('email.send_success'));
          }
        }
        this.selected = {};
      } catch (e) {
        this.$handleApiError(e);
      }
      this.sendingEmail = false;
    },
    showEmailFailedDialog(communicationResponse) {
      this.$refs.failedEmailDialog.open(communicationResponse);
    },
    async paginate(p) {
      this.pageNo = p.page;
      this.pageSize = p.itemsPerPage;
      await this.debouncedReloadApplicants();
    },
    async reloadIndustries() {
      this.loading = true;
      try {
        this.allIndustries = await this.$tenantApi.getIndustries();
      } catch (e) {
        this.$handleApiError(e);
      }
      this.loading = false;
    },
    async reloadApplicants() {
      this.applicantsLoading = true;
      try {
        const options = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          sortBy: this.sortBy,
          descending: this.sortDesc,
          jobAdId: this.job.id,
        };

        const response = await tenantApi.getSuggestedApplicants(options);

        this.applicants = response.content;
        this.totalApplicants = response.totalElements;
      } catch (error) {
        this.$handleApiError(error);
      }
      this.applicantsLoading = false;
    },
  },
  async mounted() {
    await this.reloadIndustries();
    this.loading = false;
  },
  created() {
    this.debouncedReloadApplicants = debounce(this.reloadApplicants, 300);
  },
}
</script>

<style scoped>
.applicants {
  width: 100vw;
}
.application-row-darken {
  background-color: #f5f5f5;
}
.subtitle {
  max-width: 180px;
}
</style>
