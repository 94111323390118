<template>
    <v-col cols="12" class="applicants pt-0">

      <v-fab-transition v-if="exportApplicants.length > 0">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary"
              :loading="generatingExcel"
              fab dark bottom right fixed
              @click="exportExcel"
              style="margin-bottom: 50px"
            >
              <v-icon>download</v-icon>
            </v-btn>
          </template>
          {{ $t('applicants.download_excel') }}
        </v-tooltip>
      </v-fab-transition>

      <!-- Collapsible Search Bar when mobile -->
      <v-row class="applications-search justify-center">
        <v-col cols="12" sm="12" md="10" xl="8">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-btn v-if="!isSearchVisible && !$vuetify.breakpoint.mdAndUp" @click="toggleSearch" text color="primary" class="mr-3">
                    <v-icon>search</v-icon>
                    {{ $t('job_ad.search_applications') }}
                  </v-btn>
                  <v-text-field  v-if="isSearchVisible || $vuetify.breakpoint.mdAndUp"  v-model="searchPhrase" :label="$t('job_ad.search_applications')" hide-details outlined clearable>
                    <template v-slot:append>
                      <v-icon>search</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Dropdown for Step Selector when mobile -->
              <v-row class="application-step-selector justify-center">
                <v-col cols="12">
                  <v-select
                    v-if="!$vuetify.breakpoint.mdAndUp"
                    v-model="selectedStep"
                    :items="allStatuses"
                    label="Suodata vaiheen perusteella:"
                    outlined
                    @change="selectedStepChanged"
                  ></v-select>
                  <div v-else class="d-flex flex-column">
                    <span class="title font-weight-bold">{{ $t('job_ad.step_filtering') }}</span>
                    <v-btn-toggle v-model="selectedStep" color="secondary" rounded mandatory>
                      <template v-for="step in allStatuses">
                        <v-btn
                          :small="$vuetify.breakpoint.lgAndDown"
                          :key="step.value"
                          :value="step.value"
                          @click="selectedStepChanged(step.value)"
                        >{{ step.text }}</v-btn>
                      </template>
                    </v-btn-toggle>
                  </div>
                </v-col>
              </v-row>

              <v-row class="filtterit justify-center">
                <v-col cols="12">
                  <v-expansion-panels flat class="elevation-0">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="px-0">
                        <v-badge color="red" inline :value="suodatusLuvilla && suodatusLuvilla.length" :content="suodatusLuvilla.length" class="justify-start mt-0 pt-1">
                          <span class="title mr-1">{{ $t('applicants.cards_and_permits') }}</span>
                        </v-badge>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="mx-n5">
                        <v-row no-gutters>
                          <v-col cols="12">
                            <hb-licence-selector :selected-items.sync="suodatusLuvilla" @change="handleSuodatusLuvillaChanged"></hb-licence-selector>
                          </v-col>
                          <v-col cols="12" class="mt-3" v-if="suodatusLuvilla && suodatusLuvilla.length">
                            <v-btn color="primary" block small outlined text @click="suodatusLuvilla = []">{{ $t('applicants.remove_selections') }}</v-btn>
                          </v-col>
                          <v-col cols="12" class="mt-3" v-else>
                            <v-btn color="primary" block small outlined text @click="suodatusLuvilla = job.licenses.map(l => l.code)">{{ $t('applicants.filter_by_required_licenses') }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="px-0">
                        <v-badge color="red" inline :value="selectedAnswers && selectedAnswers.length" :content="selectedAnswers.length" class="justify-start mt-0 pt-1">
                          <span class="title mr-1">{{ $t('applications.filter_by_questions_and_answers') }}</span>
                        </v-badge>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="mx-n5">
                        <v-row class="justify-center">
                          <v-col cols="12">
                            <v-select :items="filterableQuestions"
                                      v-model="questionsToFilterBy"
                                      item-text="questionText"
                                      return-object
                                      clearable
                                      multiple
                                      label="Valitse kysymykset"
                                      @change="sanitizeSelectedAnswers"></v-select>
                          </v-col>
                        </v-row>
                        <v-card color="grey lighten-3 mb-2" v-for="question in questionsToFilterBy" :key="question.id">
                          <v-col cols="12" class="secondary--text pb-0">{{ question.questionText }}</v-col>
                          <v-col cols="12" class="pt-0">
                            <v-select
                              :items="question.answers"
                              v-model="questionAnswers[question.id]"
                              item-text="answerText"
                              item-value="id"
                              clearable
                              hide-details
                              :counter="question.type === 'SELECT_MANY'"
                              :multiple="question.type === 'SELECT_MANY'"
                              label="Valitse vastaukset"
                            ></v-select>
                          </v-col>
                        </v-card>
                        <v-row no-gutters v-if="selectedAnswers && selectedAnswers.length">
                          <v-col cols="12">
                            <v-btn color="primary" block small outlined text @click="clearSelectedAnswers">{{ $t('applications.clear_filters') }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="px-0">
                        <v-badge color="red" inline :value="selectedIndustries && selectedIndustries.length" :content="selectedIndustries.length" class="justify-start mt-0 pt-1">
                          <span class="title mr-1">{{ $t('applicants.education_and_experience') }}</span>
                        </v-badge>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="mx-n5">
                        <v-row no-gutters>
                          <v-col cols="12">
                            <hb-education-selector
                              :selected-items="selectedEducation"
                              @change="handleEducationChange"
                              :all-industries="allIndustries" />
                            <hb-experience-selector
                              :selected-items="selectedExperience"
                              @change="handleExperienceChange"
                              :all-industries="allIndustries" />
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="headline">
              {{ $t('job_ad.applications') }}
            </v-card-title>
            <v-card-text>
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn
                  @click="sendEmailToSelected"
                  :loading="sendingEmail"
                  small
                  class="mb-3"
                  :disabled="selectedCount === 0">
                  {{ sendEmailToSelectedText }}
                </v-btn>
                <v-btn
                  @click="selectAll"
                  :loading="sendingEmail"
                  small
                  class="mb-3 ml-3">
                  {{ $t('applicants.select_all') }}
                </v-btn>
                <v-btn
                  @click="selected={}"
                  :loading="sendingEmail"
                  small
                  class="mb-3 ml-3"
                  :disabled="selectedCount === 0">
                  {{ $t('applicants.clear_selected') }}
                </v-btn>
              </template>
              <!-- Application List -->
              <div v-if="$vuetify.breakpoint.mdAndUp">
                <v-row class="applicant-header mt-6">
                  <v-col v-for="col in headers" :key="col.value" :cols="col.cols">
                    <span class="title">{{ col.text }}</span>
                  </v-col>
                </v-row>

                <v-row
                  v-for="(application, index) in applications"
                  :key="application.id"
                  :class="index % 2 === 0 ? 'application-row-darken' : ''"
                  @click.stop="openJobApplication(application.id)"
                  style="cursor: pointer">
                  <v-col cols="1">
                    <v-checkbox v-model="selected[application.applicant.email]" @click.stop/>
                  </v-col>
                  <v-col cols="3" >
                    <div class="font-weight-bold">{{ application.applicant.firstName }} {{ application.applicant.lastName }}</div>
                    <div class="body-2 mt-2" style="line-height: 14px;">
                      {{ application.applicant.phone }}<br>
                      {{ application.applicant.email }}
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <div class="mt-n2">
                      <div v-if="application.applicant.experience && application.applicant.experience.length" class="my-2">
                        <div class="font-weight-bold">{{ $t('experience.title') }}:</div>
                        <div class="caption" style="line-height: 14px;">
                          <template v-for="experience in application.applicant.experience">
                            <div :key="experience.id">
                              {{ experience.industry ? experience.industry.name : '' }}: {{ experience.years === 0 ? $t(`experience.none`) : experience.years > 5 ? $t(`experience.over_x_years`, {x: 5}) : `${ experience.years } ${$tc('experience.year', experience.years)}` }}
                            </div>
                          </template>
                        </div>
                      </div>
                      <div v-if="application.applicant.education && application.applicant.education.length" class="my-2">
                        <div class="font-weight-bold">{{ $t('education.title') }}:</div>
                        <div class="caption" style="line-height: 14px;">
                          <template v-for="education in application.applicant.education">
                            <div :key="education.id">
                              {{ education.industry ? education.industry.name + ': ' : '' }} {{ education.educationLevel ? $t('education.education_levels.' + education.educationLevel) : '' }}
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="1">
                    <strong>{{ applicationStatusText(application.status) }}</strong>
                  </v-col>
                  <v-col cols="1">
                    <strong>{{ applicationResultText(application.result) }}</strong>
                  </v-col>
                  <v-col cols="2">
                    <strong>{{ $formatDateNoTime(application.updated) }}</strong>
                  </v-col>
                  <v-col cols="1">
                    <v-btn small icon :to="{ name: 'single_application', params: { id: job.id, applicationId: application.id } }">
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <!-- For Mobile: Using v-data-table -->
              <v-data-table
                v-else
                :headers="headers"
                :items="applications"
                :server-items-length="totalApplications"
                :page="pageNo"
                :items-per-page="pageSize"
                :footer-props="{ 'items-per-page-options': [10, 25, 50, -1]}"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :must-sort="true"
                @update:sort-by="reloadApplications"
                @update:sort-desc="reloadApplications"
                @pagination="reloadApplications"
                class="elevation-1">
                <template v-slot:item.applicant="{ item }">
                  <strong>{{ item.applicant.firstName }} {{ item.applicant.lastName }}</strong><br>
                  {{ item.applicant.phone }}<br>
                  {{ item.applicant.email }}
                </template>
                <template v-slot:item.status="{ item }">
                  {{ applicationStatusText(item.status) }}
                </template>
                <template v-slot:item.result="{ item }">
                  {{ applicationResultText(item.result) }}
                </template>
                <template v-slot:item.updated="{ item }">
                  {{ $formatDateNoTime(item.updated) }}
                </template>
                <template v-slot:item.link="{ item }">
                  <v-btn small icon :to="{ name: 'single_application', params: { id: job.id, applicationId: item.id } }">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </template>
              </v-data-table>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <send-email-dialog ref="sendEmailDialog" />
      <failed-email-dialog ref="failedEmailDialog" />
    </v-col>
</template>
<script>
  import tenantApi from '@/api/tenant';
  import { debounce } from 'lodash';
  import HbLicenceSelector from "@/components/Tenant/HbLicenceSelector.vue";
  import HbEducationSelector from "@/components/Tenant/HbEducationSelector";
  import HbExperienceSelector from '@/components/Tenant/HbExperienceSelector';
  import XLSX from 'xlsx';
  import { saveAs } from 'file-saver';
  import SendEmailDialog from '@/components/SendEmailDialog';
  import FailedEmailDialog from "@/components/Tenant/FailedEmailDialog.vue";

  export default {
    name: 'SingleJobAdApplications',
    components: {FailedEmailDialog, HbEducationSelector, HbExperienceSelector, HbLicenceSelector, SendEmailDialog },
    props: ['job', 'allStatuses', 'allResults'],
    data() {
      return {
        applications: [],
        totalApplications: 0,
        searchPhrase: '',
        searchFilter: '',
        selectedStep: 'ALL',
        isSearchVisible: false,
        pageSize: 50,
        pageNo: 0,
        selectedLicenses: [],
        selectedEducation: [],
        selectedExperience: [],
        valitutAjokorttiLuokat: [],
        suodatusLuvilla: [],
        questionsToFilterBy: [],
        questionAnswers: {},
        sortBy: 'updated',
        sortDesc: true,
        headers: [
          { text: '', value: 'check', cols: 1 },
          { text: this.$t('job_ad.name_and_contact'), value: 'applicant', cols: '3' },
          { text: this.$t('job_ad.industries'), value: 'background', cols: '3' },
          { text: this.$t('job_ad.step'), value: 'status', cols: '1' },
          { text: this.$t('job_ad.result'), value: 'result', cols: '1' },
          { text: this.$t('job_ad.updated'), value: 'updated', cols: '2' },
          { text: '', value: 'link', cols: '1' },
        ],
        allIndustries: [],
        generatingExcel: false,
        sendingEmail: false,
        selected: {},
      };
    },
    methods: {
      selectAll() {
        this.applications.forEach(a => {
          this.$set(this.selected, a.applicant.email, true);
        });
      },
      async sendEmailToSelected() {
        this.sendingEmail = true;
        try {
          const emails = Object.entries(this.selected).filter(e => e[1]).map(e => e[0]);
          const [confirm, data] = await this.$refs.sendEmailDialog.open({}, emails);
          if (confirm) {
            const response = await tenantApi.sendEmail(data);

            if (response && response.errorCount > 0) {
              if (response.successfulCommunications && response.successfulCommunications.length > 0) {
                this.$showWarningNotification(this.$t('email.send_failed_partial'));
              } else {
                this.$showErrorNotification(this.$t('email.send_failed'));
              }
              this.showEmailFailedDialog(response);
            } else {
              this.$showSuccessNotification(this.$t('email.send_success'));
            }
          }
          this.selected = {};
        } catch (e) {
          this.$handleApiError(e);
        }
        this.sendingEmail = false;
      },
      showEmailFailedDialog(communicationResponse) {
        this.$refs.failedEmailDialog.open(communicationResponse);
      },
      exportExcel() {
        this.generatingExcel = true;

        const wb = XLSX.utils.book_new();
        // cut at 30 chars
        // Sheet name cannot contain : \ / ? * [ ]
        const ws_name = `${this.job.name}`.substring(0, 30)
          .replace(/[:\\/?*[\]]/g, '');

        const headers = [
          this.$t('applicants.name'),
          this.$t('applicants.email'),
          this.$t('applicants.phone'),
          this.$t('applicants.city'),
          this.$t('job_ad.step'),
          this.$t('job_ad.result'),
          this.$t('applicants.experience'),
          this.$t('applicants.education'),
          this.$t('applicants.updated'),
          this.$t('applicants.created'),
        ];

        /* make worksheet */
        const ws_data = [
          headers,
          ...this.exportApplicants,
        ];
        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        const wbOut = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

        function s2ab(s) {
          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
          }
          return buf;
        }

        const fileName = `${this.$t('applications.excel_filename')}_${this.job.name}.xlsx`;

        saveAs(new Blob([s2ab(wbOut)], { type: "application/octet-stream" }), fileName);

        this.generatingExcel = false;
      },
      async reloadIndustries() {
        this.loading = true;
        try {
          this.allIndustries = await this.$tenantApi.getIndustries();
        } catch (e) {
          this.$handleApiError(e);
        }
        this.loading = false;
      },
      handleSuodatusLuvillaChanged(luvat) {
        this.suodatusLuvilla = [ ...new Set(luvat)];
      },
      openJobApplication(applicationId) {
        this.$router.push({name: 'single_application', params: {id: this.job.id, applicationId: applicationId}});
      },
      toggleSearch() {
        this.isSearchVisible = !this.isSearchVisible;
      },
      async reloadApplications() {
        try {
          const options = {
            pageNo: this.$vuetify.breakpoint.smAndDown ? this.pageNo : 0, // TODO: kuuluuko olla 1 eikä 0 ?
            pageSize: this.$vuetify.breakpoint.smAndDown ? this.pageSize : -1,
            sortBy: this.sortBy,
            descending: this.sortDesc,
            jobAdId: this.job.id,
            search: this.searchFilter,
            licenses: this.suodatusLuvilla,
            status: this.selectedStep === 'ALL' ? null : this.selectedStep,
            answers: this.selectedAnswers,
            experience: this.selectedExperience,
            education: this.selectedEducation,
          };

          const response = await tenantApi.searchApplications(options);

          localStorage.setItem(`applicationsSearchOptions_${this.$tenant}`, JSON.stringify({
            ...options,
            questionAnswers: this.questionAnswers,
            questionsToFilterBy: this.questionsToFilterBy
          }));

          this.applications = response.content;
          this.totalApplications = response.totalElements;
        } catch (error) {
          this.$handleApiError(error);
        }
      },
      handleSearch(e) {
        this.searchFilter = e;
        this.reloadApplications();
      },
      applicationStatusText(status) {
        return this.$t(`application.status.${status}`);
      },
      applicationResultText(result) {
        return this.$t(`application.result.${result}`);
      },
      async valitutAjokorttiLuokatChanged() {
        await this.reloadApplications();
      },
      async selectedStepChanged(value) {
        if (value) {
          this.selectedStep = value;
        }
        await this.reloadApplications();
      },
      async selectedAnswersChanged() {
        await this.reloadApplications();
      },
      sanitizeSelectedAnswers() {
        const keys = Object.keys(this.questionAnswers);
        const answers = {...this.questionAnswers}
        for (const key of keys) {
          if (!this.questionsToFilterBy.find(q => q.id === key)) {
            delete answers[key];
          }
        }
        this.$set(this, 'questionAnswers', answers);
      },
      handleEducationChange(industries) {
        this.selectedEducation = industries;
      },
      handleExperienceChange(industries) {
        this.selectedExperience = industries;
      },
      clearSelectedAnswers() {
        this.questionAnswers = {};
        this.questionsToFilterBy = [];
      },
    },
    computed: {
      sendEmailToSelectedText() {
        return `${this.$t('applicants.send_email_to_selected')} (${this.selectedCount} ${this.$t('applicants.selected')})`;
      },
      selectedCount() {
        return Object.values(this.selected).filter(v => v).length;
      },
      exportApplicants() {
        return this.applications.map(application => {
          const a = application.applicant;
          const experience = a.experience.map(e => {
            const years = e.years > 5 ? '>5' : `${e.years}`;
            return `${e.industry.name} (${years})`
          }).join(', ');

          const education = a.education.map(e => {
            const industry = e.industry ? e.industry.name + ': ' : '';
            const level = e.educationLevel ? this.$t('education.education_levels.' + e.educationLevel) : '';
            return `${industry}${level}`
          }).join(', ');

          return [
            `${a.firstName} ${a.lastName}`,
            a.email || '',
            a.phone || '',
            a.city || '',
            this.applicationStatusText(application.status),
            this.applicationResultText(application.result),
            experience,
            education,
            this.$formatDateNoTime(application.updated),
            this.$formatDateNoTime(application.created),
          ];
        });
      },
      selectedIndustries() {
        return [...this.selectedEducation, ...this.selectedExperience];
      },
      filterableQuestions() {
        return this.job.questions.filter(q => q.type !== 'FREE_TEXT');
      },
      selectedAnswers() {
        const answers = [];
        for (const q in this.questionAnswers) {
          const a = this.questionAnswers[q];
          if (a) {
            if (typeof a === 'number') {
              answers.push(a);
            } else {
              answers.push(...a);
            }
          }
        }
        return answers;
      },
    },
    watch: {
      searchPhrase(to) {
        this.debouncedSearch(to);
      },
      questionsToFilterBy(to, from) {
        if (to.length < from.length) {
          const removed = from.filter(x => !to.includes(x));
          for (const r of removed) {
            delete this.questionAnswers[r.id];
          }
        }
      },
      selectedAnswers() {
        this.reloadApplications();
      },
      suodatusLuvilla() {
        this.debouncedReload();
      },
      selectedEducation() {
        this.debouncedReload();
      },
      selectedExperience() {
        this.debouncedReload();
      },
    },
    created() {
      // debounce for searchPhrase watcher
      this.debouncedSearch = debounce(this.handleSearch, 450);
      this.debouncedReload = debounce(this.reloadApplications, 450);
    },
    async mounted() {
      const optionsJson = localStorage.getItem(`applicationsSearchOptions_${this.$tenant}`);
      if (optionsJson) {
        const options = JSON.parse(optionsJson);
        if (options.jobAdId === this.job.id) {
          this.searchPhrase = options.search; // this.searchFilter?
          this.suodatusLuvilla = options.licenses;
          this.selectedStep = options.status;
          this.questionAnswers = options.questionAnswers;
          this.questionsToFilterBy = options.questionsToFilterBy;
          this.selectedExperience = options.experience;
          this.selectedEducation = options.education;
        }
      }

      await this.reloadApplications();
      await this.reloadIndustries();
    },
  };
</script>
<style scoped>
.applicants {
  width: 100vw;
}
.application-row-darken {
  background-color: #f5f5f5;
}
</style>
