<template>
  <hb-basic-page :title="$t('applicants.title')"
                 :loading="loading"
                 :subtle-loading="subtleLoading"
                 :fill-height="loading || applicant === null"
                 :no-gutters="true"
                 :back-button="{show: true, fallback: 'applicants'}"
                 name="single-applicant-view">
    <v-col cols="12"  v-if="!loading && applicant">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8" xl="6">
          <v-row justify="center">
            <v-col cols="12">
              <v-card>
                <div v-if="$vuetify.breakpoint.xsOnly && applicant.profilePictureUrl" class="rounded-t">
                  <v-img :src="applicant.profilePictureUrl"
                         :alt="applicant.firstName"
                         width="100%"></v-img>
                </div>
                <v-card-text>
                  <v-row justify="start">
                    <div v-if="$vuetify.breakpoint.smAndUp" class="px-5">
                      <v-avatar size="120" color="grey lighten-3">
                        <v-img v-if="applicant.profilePictureUrl"
                               :src="applicant.profilePictureUrl"
                               :alt="applicant.firstName"
                               width="100px"
                               height="100px"></v-img>
                        <v-icon v-else color="grey lighten-1" size="42">mdi mdi-image</v-icon>
                      </v-avatar>
                    </div>
                    <div class="px-3 py-0">
                      <v-btn color="primary" small outlined text @click="editApplicant" :loading="loading">
                        <v-icon left>mdi-pencil</v-icon>
                        {{$t('applicants.edit')}}
                      </v-btn>
                      <h2 class="headline black--text mt-2 grow">{{ applicant.firstName }} {{ applicant.lastName }}</h2>
                      <div class="my-1"><v-icon size="18" color="secondary" class="mr-1">mdi mdi-cellphone</v-icon> <a :href="`tel:${applicant.phone}`">{{ applicant.phone }}</a></div>
                      <div class="my-1">
                        <v-icon size="18" color="secondary" class="mr-1">mdi mdi-email</v-icon>
                        <a :href="`mailto:${applicant.email}`">{{ applicant.email }}</a>
                        <v-btn icon small :loading="sendingEmail" @click="sendEmail"><v-icon>send</v-icon></v-btn>
                      </div>
                      <div class="my-1"><v-icon size="18" color="secondary" class="mr-1">mdi mdi-home</v-icon>{{ applicant.address }} {{ applicant.zipCode }} {{ applicant.city }}</div>
                      <div class="my-1"><v-icon size="18" color="secondary" class="mr-1 mt-n1">mdi mdi-creation</v-icon> {{ $t('applicants.created') }}: {{ $formatDateNoTime(applicant.created) }}, {{ $t('applicants.updated') }}: {{ $formatDateNoTime(applicant.updated) }}</div>
                    </div>
                  </v-row>
                  <v-row v-if="applicant.ownDescription">
                    <v-col cols="12">
                      <p>{{ applicant.ownDescription }}</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div class="mt-4">
                        <template v-if="applicantLicenses && applicantLicenses.length">
                          <h3>{{ $t('applicants.cards_and_permits') }}</h3>
                          <v-chip-group column multiple>
                            <v-chip
                              v-for="chip in applicantLicenses"
                              :key="chip.id"
                              :small="$vuetify.breakpoint.xsOnly"
                              :value="chip.license.code"
                              color="primary"
                            >
                              <v-icon left>mdi-check</v-icon>
                              {{ $t(`licenses.${chip.license.code}`) }}
                            </v-chip>
                          </v-chip-group>
                        </template>
                        <template v-if="applicantDrivingLicenses && applicantDrivingLicenses.length" >
                          <h3 class="mt-2">{{ $t('applicants.driving_licenses') }}:</h3>
                          <v-chip-group column multiple>
                            <v-chip
                              v-for="chip in applicantDrivingLicenses"
                              :key="chip.id"
                              :small="$vuetify.breakpoint.xsOnly"
                              :value="chip.license.code"
                              color="primary"
                            >
                              <v-icon left>mdi-check</v-icon>
                              {{ $te(`licenses.${chip.license.code}`) ? $t(`licenses.${chip.license.code}`) : chip.license.code }}
                            </v-chip>
                          </v-chip-group>
                        </template>

                        <template v-if="applicant.experience && applicant.experience.length > 0">
                          <h3 class="mt-2 mb-1">{{ $t('application.experience') }}</h3>
                          <template v-for="experience in applicant.experience">
                            <div :key="experience.id">
                              <strong>{{ experience.industry.name }}:</strong> {{ experience.years === 0 ? $t(`experience.none`) : experience.years > 5 ? $t(`experience.over_x_years`, {x: 5}) : `${ experience.years } ${$tc('experience.year', experience.years)}` }}
                            </div>
                          </template>
                        </template>

                        <template v-if="applicant.education && applicant.education.length > 0">
                          <h3 class="mt-2 mb-1">{{ $t('application.education') }}</h3>
                          <template v-for="education in applicant.education">
                            <div :key="education.id">
                              <strong v-if="education.industry">{{ education.industry.name }}<template v-if="education.educationLevel">:</template></strong> {{ education.educationLevel && $t(`education.education_levels.${education.educationLevel}`) }}
                            </div>
                          </template>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" v-for="tab in enabledTabs" :key="tab.id">
              <v-card>
                <v-card-title class="headline">
                  {{ tab.title }} <span v-if="tab.component === 'applicant-applications' && activeApplicationsCount > 0" class="ml-2">({{ activeApplicationsCount }})</span>
                </v-card-title>
                <v-card-text :class="[tab.type === 'list' && 'pa-0']" style="overflow: hidden;">
                  <component :is="tab.component" v-bind="tab.props" v-on="tab.on"></component>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Pois nyt toistaiseksi, jos tää otetaan käyttöön niin tuo yläpuolelta pois sitten
            <v-col cols="12">
              <v-card>
                <v-tabs grow show-arrows>
                  <v-tab v-for="tab in enabledTabs" :key="tab.id">
                    {{ tab.title }} <span v-if="tab.id === '5' && activeApplicationsCount > 0" class="ml-2">({{ activeApplicationsCount }})</span>
                  </v-tab>
                  <v-tab-item v-for="tab in enabledTabs" :key="tab.id">
                    <v-card flat>
                      <v-card-text>
                        <component :is="tab.component" v-bind="tab.props"></component>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-col>
            -->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <edit-applicant-dialog
      ref="editApplicantDialog"
      @save="saveApplicant"
      :licenses="licenses"
      :industries="industries"
    />
    <send-email-dialog ref="sendEmailDialog" />
    <failed-email-dialog ref="failedEmailDialog" />
  </hb-basic-page>
</template>

<script>
import tenantApi from "@/api/tenant";
import ApplicantCv from "@/components/ApplicantCv.vue";
import InternalDiscussion from "@/components/Tenant/InternalDiscussion.vue";
import ApplicantApplications from "@/components/Tenant/ApplicantApplications.vue";
import EditApplicantDialog from '@/components/Tenant/Applicant/EditApplicantDialog';
import sharedApi from '@/jobs/api/shared';
import SendEmailDialog from '@/components/SendEmailDialog';
import FailedEmailDialog from "@/components/Tenant/FailedEmailDialog.vue";

export default {
  name: "ApplicantView",
  props: ['id'],
  components: {
    FailedEmailDialog,
    EditApplicantDialog,
    SendEmailDialog,
    'applicant-cv': ApplicantCv,
    'internal-discussion': InternalDiscussion,
    'applicant-applications': ApplicantApplications
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      applicant: null,
      files: [],
      licenses: [],
      industries: [],
      sendingEmail: false,
    }
  },
  methods: {
    async sendEmail() {
      this.sendingEmail = true;
      try {
        const emails = [this.applicant.email];
        const [confirm, data] = await this.$refs.sendEmailDialog.open({}, emails);
        if (confirm) {
          const response = await tenantApi.sendEmail(data);

          if (response && response.errorCount > 0) {
            if (response.successfulCommunications && response.successfulCommunications.length > 0) {
              this.$showWarningNotification(this.$t('email.send_failed_partial'));
            } else {
              this.$showErrorNotification(this.$t('email.send_failed'));
            }
            this.showEmailFailedDialog(response);
          } else {
            this.$showSuccessNotification(this.$t('email.send_success'));
          }
        }
      } catch (e) {
        this.$handleApiError(e);
      }
      this.sendingEmail = false;
    },
    showEmailFailedDialog(communicationResponse) {
      this.$refs.failedEmailDialog.open(communicationResponse);
    },
    async applicationsUpdated() {
      await this.reloadApplicant();
    },
    async editApplicant() {
      this.$refs.editApplicantDialog.open(this.applicant);
    },
    async saveApplicant(applicant) {
      try {
        await tenantApi.updateApplicant(this.id, applicant);
        await this.reloadApplicant();
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    async reloadApplicant() {
      if (this.id) {
        this.subtleLoading = true;
        try {
          const applicant = await tenantApi.getApplicantById(this.id);
          await this.reloadFiles();
          this.licenses = await sharedApi.getLicenses();
          this.industries = await tenantApi.getIndustries();
          this.applicant = applicant;
        } catch (e) {
          this.$handleApiError(e);
        }
        this.subtleLoading = false;
      }
    },
    async reloadFiles() {
      try {
        this.files = await tenantApi.getFilesByApplicant(this.id);
      } catch (e) {
        this.$handleApiError(e)
      }
    }
  },
  computed: {
    tabs() {
      return [
        {
          id: '1',
          title: this.$t('applicants.tabs.messages'),
          component: 'applicant-messages',
          enabled: false
        },
        {
          id: '2',
          title: this.$t('applicants.tabs.applications'),
          component: 'applicant-applications',
          props: {
            applicant: this.applicant,
            applications: this.applicant.applications,
          },
          on: {
            updated: this.applicationsUpdated,
          },
          enabled: true,
          type: 'list',
        },
        {
          id: '3',
          title: this.$t('applicants.tabs.cv'),
          component: 'applicant-cv',
          props: {
            applicant: this.applicant,
            files: this.files.filter(f => f.type === 'CV'),
            type: 'CV',
            noFilesTitle: this.$t('applicants.no_cv'),
            noFilesMessage: this.$t('applicants.no_cv_help'),
          },
          on: {
            updated: this.reloadFiles,
          },
          enabled: true,
          type: 'list',
        },
        {
          id: '4',
          title: this.$t('applicants.tabs.files'),
          component: 'applicant-cv',
          props: {
            applicant: this.applicant,
            type: 'ADDITIONAL_FILE',
            files: this.files.filter(f => f.type !== 'CV'),
            noFilesTitle: this.$t('applicants.no_files'),
            noFilesMessage: this.$t('applicants.no_files_help'),
          },
          on: {
            updated: this.reloadFiles,
          },
          enabled: true,
          type: 'list',
        },
        {
          id: '5',
          title: this.$t('applicants.tabs.referrals'),
          component: 'applicant-referrals',
          enabled: false
        },
        {
          id: '6',
          title: this.$t('applicants.tabs.work_history'),
          component: 'applicant-work-history',
          enabled: false
        },
        {
          id: '7',
          title: this.$t('applicants.tabs.internal_discussion'),
          component: 'internal-discussion',
          enabled: false
        }
      ]
    },
    enabledTabs() {
      return [...this.tabs].filter(t => t.enabled)
    },
    activeApplicationsCount() {
      return this.applicant.applications?.filter(a => a.result === 'NONE').length || [];
    },
    applicantLicenses() {
      return this.applicant?.licenses?.filter(l => l.license.category === 'LICENSE' && !l.deleted);
    },
    applicantDrivingLicenses() {
      return this.applicant?.licenses?.filter(l => l.license.category === 'DRIVING' && !l.deleted);
    },
  },
  mounted() {
    this.loading = false;
    this.reloadApplicant();
  }
}
</script>

<style scoped>

</style>
