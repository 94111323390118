<template>
  <v-container fluid :fill-height="fillHeight" :class="name" :pa-0="noContainerPadding">

    <v-progress-linear style="position: fixed; top: 0; left: 0; right: 0; margin: 0; z-index: 99;"  color="secondary"
                       height="3" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-app-bar color="white" light fixed app :extended="false">

      <template v-if="$vuetify.breakpoint.mdAndDown && !showBackButton">
        <hb-main-menu-button/>
      </template>

      <template v-if="showSearch">
        <v-toolbar-title style="margin-left: 0; width: 100%;" class="title">
          <v-text-field
            :placeholder="searchTitle"
            v-model="searchPhrase"
            class="topbar-search"
            style="font-size: inherit;"
            single-line
            full-width
            hide-details
            color="red lighten-3"
          >
            <v-icon style="cursor: pointer;" slot="append">search</v-icon>
          </v-text-field>
        </v-toolbar-title>
      </template>

      <template v-else>
        <hb-back-button v-if="showBackButton" :fallback="this.backButton.fallback"></hb-back-button>
        <v-toolbar-title style="margin-left: 0; width: 100%;" class="title">
          {{ title }}
        </v-toolbar-title>

        <template v-if="contextMenu && contextMenu.length > 0">
          <v-spacer></v-spacer>

          <v-menu dark>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon>
                <v-icon>mdi mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <template v-for="item in contextMenu">
                <v-list-item v-if="item.url" :key="item.text" :href="item.url" :target="item.target">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-else :key="item.text" @click="item.callback">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </template>

            </v-list>
          </v-menu>
        </template>
      </template>

      <v-spacer></v-spacer>

      <template v-if="!!$slots['app-bar-extension']" v-slot:extension>
        <slot name="app-bar-extension"></slot>
      </template>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;" :no-gutters="noGutters">
        <slot></slot>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
export default {
  props: ['name', 'title', 'loading', 'subtleLoading', 'backButton', 'searchTitle', 'noGutters', 'noContainerPadding', 'fillHeight', 'contextMenu'],
  data() {
    return {
      searchPhrase: '',
    }
  },
  computed: {
    showBackButton() {
      return this.backButton != null && this.backButton.show;
    },
    showSearch() {
      return this.searchTitle != null;
    },
  },
  methods: {
    clearSearch() {
      this.searchPhrase = "";
    },
  },
  watch: {
    searchPhrase(to) {
      this.$emit('handle-search', to);
    }
  }
}
</script>

<style lang="scss">
  .topbar-search {
    .v-input__slot {
      padding: 0 !important;

      &:after {
        display: none;
      }
    }
  }
</style>
